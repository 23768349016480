import './MyGridComponent.css';

// import btn1 from '../docs/EN/btn1.png';
// import btn2 from '../docs/EN/btn2.png';
// import btn3 from '../docs/EN/btn3.png';
// import btn4 from '../docs/EN/btn4.png';
// import btn5 from '../docs/EN/btn5.png';
import btn6 from '../docs/guide_btn.png';



import React, { useContext, useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import ContentClimateChangeMitigation from './contents/ContentClimateChangeMitigation';
import ContentClimateChangeAdaptation from './contents/ContentClimateChangeAdaptation';
import ContentCircularEconomy from './contents/ContentCircularEconomy';
import ContentDigitalization from './contents/ContentDigitalization';
import ContentEnergyTransition from './contents/ContentEnergyTransition';
import { LanguageContext, LanguageProvider } from './LanguageProvider';
import ContentGuide from './contents/ContentGuide';






function MyGridComponent({ title1, title2, title3, title4 }) {
    const [activeButton, setActiveButton] = useState('');
    const { language, setLanguage } = useContext(LanguageContext);
    const [imageModules, setImageModules] = useState({});

    useEffect(() => {
        const loadImages = async () => {
            try {
                const loadedImages = {
                    btn1: await import(`../docs/${language}/btn1.png`),
                    btn2: await import(`../docs/${language}/btn2.png`),
                    btn3: await import(`../docs/${language}/btn3.png`),
                    btn4: await import(`../docs/${language}/btn4.png`),
                    btn5: await import(`../docs/${language}/btn5.png`),
                };

                setImageModules(loadedImages);
            } catch (error) {
                console.error('Error loading images:', error);
            }
        };

        loadImages();
    }, [language]);



    const backtrans = {
        EN: 'Back', // English
        ES: 'Atrás', // Spanish
        GR: 'Πίσω', // Greek
        IT: 'Indietro', // Italian
        HU: 'Vissza', // Hungarian
        LV: 'Atpakaļ' // Latvian
    };

    const handleClick = (buttonId) => {
        setActiveButton(buttonId);
    };

    const closeContent = () => {
        setActiveButton('');
    }



    const changeLanguage = (e) => {
        setLanguage(e.target.value);
    };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const language = params.get('lang');
        if (language) {
            setLanguage(language);
        }
    }, []);




    return (
        <><div className='language-container'>

            <select value={language} onChange={changeLanguage}>
                <option value="EN">English</option>
                <option value="ES">Español</option>
                <option value="GR">Ελληνικά</option>
                <option value="IT">Italiano</option>
                <option value="HU">Magyar</option>
                <option value="LV">Latviešu</option>
            </select>

        </div>
            <div className='grid-component'>

                <div className='wrapper'>
                    {activeButton ? (
                        <div className="new-content">
                            {activeButton === 'item1' && <div> <ContentClimateChangeMitigation />  <div className='item-button-back'><IconButton className='back-button' onClick={closeContent}>{backtrans[language]}</IconButton></div></div>}
                            {activeButton === 'item2' && <div> <ContentClimateChangeAdaptation /> <div className='item-button-back'><IconButton className='back-button' onClick={closeContent}>{backtrans[language]}</IconButton></div></div>}
                            {activeButton === 'item3' && <div> <ContentCircularEconomy /> <div className='item-button-back'><IconButton className='back-button' onClick={closeContent}>{backtrans[language]}</IconButton></div></div>}
                            {activeButton === 'item4' && <div> <ContentDigitalization /> <div className='item-button-back'><IconButton className='back-button' onClick={closeContent}>{backtrans[language]}</IconButton></div></div>}
                            {activeButton === 'item5' && <div> <ContentEnergyTransition /><div className='item-button-back'><IconButton className='back-button' onClick={closeContent}>{backtrans[language]}</IconButton></div></div>}
                            {/* {activeButton === 'item5' && <div> <LanguageProvider><ContentEnergyTransition /></LanguageProvider> <div className='item-button-back'><IconButton className='back-button' onClick={closeContent}>Back</IconButton></div></div>} */}
                            {activeButton === 'item6' && <div> <ContentGuide /><div className='item-button-back'><IconButton className='back-button' onClick={closeContent}>{backtrans[language]}</IconButton></div></div>}
                        </div>
                    ) : (
                        <>
                            {/* <div className="grid-container-titles1">
                            <h5 className='title1' id="t1">{title1}</h5>
                            <h5 className='title1' id="t2">{title2}</h5>
                        </div> */}

                            <div className="grid-container">
                                <IconButton className="grid-item " id="item1" onClick={() => handleClick('item1')} >
                                    <img src={imageModules.btn1?.default} alt="1" />
                                </IconButton>

                                <IconButton className="grid-item" id="item2" onClick={() => handleClick('item2')}>
                                    <img src={imageModules.btn2?.default} alt="2" />
                                </IconButton>

                                <IconButton className="grid-item" id="item3" onClick={() => handleClick('item3')}>
                                    <img src={imageModules.btn3?.default} alt="3" />
                                </IconButton>

                                <IconButton className="grid-item" id="item4" onClick={() => handleClick('item4')}>
                                    <img src={imageModules.btn4?.default} alt="4" />
                                </IconButton>

                                <IconButton className="grid-item" id="item5" onClick={() => handleClick('item5')}>
                                    <img src={imageModules.btn5?.default} alt="5" />
                                </IconButton>
                                {/* <IconButton className="grid-item " id="item1" onClick={() => handleClick('item1')} >
                                    <img src={btn1} alt="1" />
                                </IconButton>

                                <IconButton className="grid-item" id="item2" onClick={() => handleClick('item2')}>
                                    <img src={btn2} alt="2" />
                                </IconButton>

                                <IconButton className="grid-item" id="item3" onClick={() => handleClick('item3')}>
                                    <img src={btn3} alt="3" />
                                </IconButton>

                                <IconButton className="grid-item" id="item4" onClick={() => handleClick('item4')}>
                                    <img src={btn4} alt="4" />
                                </IconButton>

                                <IconButton className="grid-item" id="item5" onClick={() => handleClick('item5')}>
                                    <img src={btn5} alt="5" />
                                </IconButton> */}

                            </div>
                            <div class="guide-container">
                                <IconButton className="guide-item" id="item6" onClick={() => handleClick('item6')}>
                                    <img src={btn6} alt="6" />
                                </IconButton>
                            </div>
                            {/* <div className="grid-container-titles2">
                            <h5 className='title2' id="t3">{title3}</h5>
                            <h5 className='title2' id="t4">{title4}</h5>
                        </div> */}
                        </>
                    )}
                </div>

            </div >
        </>);
}

MyGridComponent.propTypes = {
    title1: PropTypes.string.isRequired,
    title2: PropTypes.string.isRequired,
    title3: PropTypes.string.isRequired,
    title4: PropTypes.string.isRequired,
};

export default MyGridComponent;
