import React, { createContext, useState, useEffect } from 'react';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState('EN');

    const [buttonText, setButtonText] = useState('English');  // Just a single button text state

    useEffect(() => {
        const textsInLanguages = {
            EN: 'English',
            ES: 'Español',
            GR: 'Ελληνικά',
            IT: 'Italiano',
            HU: 'Magyar',
            LV: 'Latviešu',
        };

        setButtonText(textsInLanguages[language]);
    }, [language]);

    return (
        <LanguageContext.Provider value={{ language, setLanguage, buttonText }}>
            {children}
        </LanguageContext.Provider>
    );
};