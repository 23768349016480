import React, { useEffect, useRef } from 'react';
import * as Zdog from 'zdog';
import '../../MyGridComponent.css';


function BirdAnimation() {
    const canvasRef = useRef(null);

    useEffect(() => {
        if (canvasRef.current) {
            // Your existing Zdog code goes here, but with a few modifications

            const pic = new Zdog.Illustration({
                element: canvasRef.current, // Attach Zdog to the canvas using a ref
            });

            const headR = 20;
            const beakZ = 15;

            const coneR = Math.sqrt(headR * headR - beakZ * beakZ);
            const coneH = coneR * headR / beakZ;

            class Actor {
                constructor(parent, object) {
                    if (parent && object) parent.object.addChild(object);
                    Actor.allActors.push(this);
                }
            }
            Actor.allActors = [];

            class Eye extends Actor {
                constructor(parent, side) {
                    const s = side === 'left' ? -1 : 1;

                    const object = new Zdog.Shape({
                        rotate: { y: s * Math.PI / 3, x: Math.PI / 7 }
                    })

                    const eye = new Zdog.Shape({
                        addTo: object,
                        stroke: 5,
                        color: 'black',
                        translate: { z: headR },
                    })

                    super(parent, object);
                }
            }

            class Head extends Actor {
                constructor(parent) {
                    const object = new Zdog.Shape({
                        stroke: 2 * headR,
                        color: '#097969',
                    })

                    super(parent, object);
                    this.object = object;
                    const leftEye = new Eye(this, 'left');
                    const rightEye = new Eye(this, 'right');
                    const beak = new Beak(this);
                }
            }

            class Tail extends Actor {
                constructor(parent) {
                    const object = new Zdog.Shape({
                        color: '#097969',
                        fill: true,
                        stroke: 1,
                        path: [{
                            x: -40, y: -100
                        }, {
                            x: 40, y: -100
                        }, {
                            x: 10, y: 0
                        }, {
                            x: -10, y: 0
                        }],
                        rotate: { x: Math.PI / 2 + .5 },
                        translate: { z: 10 }
                    })

                    super(parent, object);
                    this.object = object;
                }
            }


            class Wing extends Actor {
                constructor(parent, side) {
                    const counter = 0;

                    const s = side === 'left' ? 1 : -1;

                    const object = new Zdog.Shape({
                        translate: {
                            x: s * 20,
                        },
                        stroke: false
                    });

                    const pivot = new Zdog.Shape({
                        addTo: object,
                        rotate: { y: -Math.PI / 2, z: -.1 },
                        stroke: false
                    });

                    const shape = new Zdog.Ellipse({
                        addTo: pivot,
                        stroke: 1,
                        width: 75,
                        height: 75,
                        quarters: 2,
                        fill: true,
                        closed: true,
                        color: '#097969',
                        translate: { y: 45 }
                    })

                    super(parent, object);

                    this.object = object;
                    this.s = s;
                    this.counter = counter;
                }

                update(deltaTime) {
                    this.counter += deltaTime * (60 / 1000);
                    // appx "natural" browser frame rate is 60/1000ms
                    this.object.rotate.z = -this.s * (.3 + 2.2 * (Math.sin(this.counter / 13) + 1) / 2);
                }
            }


            class Body extends Actor {
                constructor(parent) {
                    const object = new Zdog.Shape({
                        path: [{ z: -10 }, { z: -25, y: 5 }],
                        stroke: 50,
                        translate: { z: -30, y: 30 },
                        color: '#097969',
                    })
                    super(parent, object);

                    this.object = object;

                    const leftLeg = new Leg(this);
                    leftLeg.object.translate = { x: 8 };

                    const rightLeg = new Leg(this);
                    rightLeg.object.translate = { x: -8 };

                    const tail = new Tail(this);
                }
            }

            class Beak extends Actor {
                constructor(parent) {
                    const object = new Zdog.Cone({
                        diameter: 5,
                        length: 10,
                        color: '#f20',
                        stroke: false,
                        translate: { z: beakZ }
                    })
                    super(parent, object);
                }
            }


            class Bird extends Actor {
                constructor() {

                    const object = new Zdog.Shape({
                        addTo: pic
                    });

                    super();

                    this.counter = 0;

                    this.object = object;
                    const objects = [];

                    const head = new Head(this);
                    const body = new Body(this);

                    const wingLeft = new Wing(body, 'left');
                    const wingRight = new Wing(body, 'right');

                    this.objects = objects;
                }

                update(deltaTime) {
                    this.counter += deltaTime * (60 / 1000);
                    this.object.translate.y = -50 + 100 * (Math.sin(this.counter / 13) + 1) / 2;
                    this.objects.forEach(object => {
                        if (object.update) object.update(deltaTime);
                    })
                }
            }

            class Leg extends Actor {
                constructor(parent) {
                    const object = new Zdog.Shape();

                    const leg = new Zdog.Shape({
                        path: [{ y: 0, x: 0 }, { y: 0, x: 30 }, { y: -20, x: 30 }],
                        color: "f20",
                        stroke: 2,
                        closed: false,
                        rotate: { y: -Math.PI / 2 },
                        translate: { y: 30, z: -20 }
                    })

                    const CLAW_RADIUS = 5;
                    const claw = new Zdog.Ellipse({
                        addTo: leg,
                        diameter: CLAW_RADIUS * 2,
                        stroke: 2,
                        color: '#F20',
                        quarters: 3,
                        translate: { y: CLAW_RADIUS },
                        rotate: { z: Math.PI }
                    })

                    object.addChild(leg);
                    super(parent, object);
                    this.object = object;
                }
            }

            const bird = new Bird();


            pic.updateRenderGraph();

            let i = 0;

            function animator() {
                let prevTime = 0;
                function animate(time) {
                    const deltaTime = time - prevTime;
                    prevTime = time;

                    Actor.allActors.forEach(a => { if (a.update) a.update(deltaTime) });

                    requestAnimationFrame(animate);
                    pic.rotate.y = 120;
                    pic.updateRenderGraph();
                }
                requestAnimationFrame(animate);
            }

            animator();
        }
    }, []); // Empty dependency array means this useEffect runs once after mount

    return <canvas ref={canvasRef} className="zdog-canvas" width="600" height="400"></canvas>;
}

export default BirdAnimation;
