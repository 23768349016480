// Button 3: Circular Economy

import React, { useContext, useState, useEffect } from 'react';

import parse from 'html-react-parser';

import { IconButton } from '@mui/material';
import './content.css';
import './bubbleppu.css'
import BirdAnimation from './bird/BirdAnimation';






import img1 from './circular_economy_images/150/31.jpg';
import img2 from './circular_economy_images/150/32.jpg';
import img3 from './circular_economy_images/150/33.jpg';
import img4 from './circular_economy_images/150/34.jpg';
import img5 from './circular_economy_images/150/35.jpg';
import img6 from './circular_economy_images/150/36.jpg';

import { LanguageContext } from '../LanguageProvider';
// import LanguageSelector from '../LanguageSelector';


let timeoutId = null; // Declare this outside of the handleClickAnswer function


function ContentCircularEconomy() {

    const [activeButton, setActiveButton] = useState('item1');
    const [data, setData] = useState([]);
    const [selectedData, setSelectedData] = useState(null); // Added this line to manage the selected data
    const [showBubble, setShowBubble] = useState(false);
    const [popupMessage, setPopupMessage] = useState(''); // New state variable for the popup message
    const [showBird, setShowBird] = useState(false);
    // const [handleNext, setHandleNext] = useState(false);

    const { language } = useContext(LanguageContext);


    const nextrans = {
        EN: 'Next', // English
        ES: 'Siguiente', // Spanish
        GR: 'Επόμενο', // Greek
        IT: 'Prossimo', // Italian
        HU: 'Következő', // Hungarian
        LV: 'Nākamais' // Latvian
    };



    // For next button 

    const itemIndexMap = {
        'item1': 0,
        'item2': 1,
        'item3': 2,
        'item4': 3,
        'item5': 4,
        'item6': 5
    };

    const currentIndex = itemIndexMap[activeButton];



    const fetchData = async () => {
        try {
            const response = await fetch('/contentquiz_multilanguage3_circular_economy.json');
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            const jsonData = await response.json();
            setData(jsonData);
            const defaultData = jsonData[language].questions.find((question) => question.item === activeButton);
            if (defaultData) {
                setSelectedData(defaultData); // Set default content to item1 data
            }
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        // setActiveButton(currentIndex);
        fetchData();
        // console.log('DEBUG ACTIVE BUTTON: ', activeButton);
        // setActiveButton(activeButton);
    }, [language]);






    const handleClick = (buttonId) => {
        setShowBubble(false);  // reset button on category change
        setShowBird(false); //reset button on category change
        setActiveButton(buttonId);
        if (data[language]) {
            console.error(buttonId);
            const questionData = data[language].questions.find((question) => question.item === buttonId);
            // console.log(questionData)
            if (!questionData) {
                // console.error("Item not found");
                return;
            }

            setSelectedData(questionData); // Updated this line to set the selected data
        }
    };

    const handleNext = (data) => {
        if (currentIndex === 5) {
            handleClick('item1');
        } else {
            handleClick('item' + (currentIndex + 2));
        }
    };




    const handleClickAnswer = (answer, index) => {
        // console.log("Clicked index:", index);
        setShowBubble(true);  // Show the bubble and bird
        setShowBird(true);
        setPopupMessage(selectedData.popup[index]); // Update the popup message

        if (timeoutId) {
            clearTimeout(timeoutId); // Clear the existing timeout if there is one
        }

        timeoutId = setTimeout(() => {
            setShowBubble(false);
            setShowBird(false);
        }, 10000);
    };


    return (
        <div className='return-div-energy-transition'>
            {/* <div className='language-container'>

                <select value={language} onChange={changeLanguage}>
                    <option value="EN">English</option>
                    <option value="ES">Español</option>
                    <option value="GR">Ελληνικά</option>
                    <option value="IT">Italiano</option>
                    <option value="HU">Magyar</option>
                    <option value="LV">Latviešu</option>
                </select>

            </div> */}

            <div className='grid-xbuttons-questionshugger'>

                <div className='row-xcolumns-grid-xbuttons-images6'>
                    <IconButton
                        className={activeButton === 'item1' ? "grid-item-energy" : "grid-item-energy desaturated"}
                        id="choice1-energy"
                        onClick={() => handleClick('item1')}>
                        <img src={img1} alt="grid-item-energy1" />
                    </IconButton>
                    <IconButton
                        className={activeButton === 'item2' ? "grid-item-energy" : "grid-item-energy desaturated"}
                        id="choice2-energy"
                        onClick={() => handleClick('item2')}>
                        <img src={img2} alt="grid-item-energy2" />
                    </IconButton>
                    <IconButton
                        className={activeButton === 'item3' ? "grid-item-energy" : "grid-item-energy desaturated"}
                        id="choice3-energy"
                        onClick={() => handleClick('item3')}>
                        <img src={img3} alt="grid-item-energy3" />
                    </IconButton>
                    <IconButton
                        className={activeButton === 'item4' ? "grid-item-energy" : "grid-item-energy desaturated"}
                        id="choice4-energy"
                        onClick={() => handleClick('item4')}>
                        <img src={img4} alt="grid-item-energy4" />
                    </IconButton>
                    <IconButton
                        className={activeButton === 'item5' ? "grid-item-energy" : "grid-item-energy desaturated"}
                        id="choice5-energy"
                        onClick={() => handleClick('item5')}>
                        <img src={img5} alt="grid-item-energy5" />
                    </IconButton>
                    <IconButton
                        className={activeButton === 'item6' ? "grid-item-energy" : "grid-item-energy desaturated"}
                        id="choice6-energy"
                        onClick={() => handleClick('item6')}>
                        <img src={img6} alt="grid-item-energy6" />
                    </IconButton>
                </div>
                <div className='question-answers-bg-content'>
                    <div className="new-content">
                        {selectedData && (  // Updated this line to check if selectedData is not null
                            <div className="content-container">
                                <h4 className='question-header'>{selectedData.question}</h4>
                                <div className='answers-nextbutton row'>
                                    <ul className='answers-quiz'>
                                        {selectedData.answers.map((answer, index) => (
                                            <li key={index}>
                                                <button onClick={(e) => handleClickAnswer(e, index)} style={{ border: 'none', background: 'none', padding: 5 }}>
                                                    <p style={{ textAlign: 'justify', cursor: 'pointer' }}>{answer}</p>
                                                </button>
                                            </li> // <li key={index}>{answer}</li>
                                        ))
                                        }
                                    </ul>
                                    <button onClick={(e) => handleNext(currentIndex)} className='next-button'>{nextrans[language]}</button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="wrapper-popups">
                    <div className="flex-container">
                        <div className="item-popup bubble-container">
                            <div className={`bubble right ${showBubble ? 'show' : ''}`}>
                                {parse(popupMessage)}
                            </div>
                        </div>
                        <div className="item-popup bird-container">
                            <div className={`popup-bird  ${showBird ? 'show' : ''}`}>
                                <BirdAnimation />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContentCircularEconomy;
