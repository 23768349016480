// Button 3: Circular Economy

import React, { useContext, useState, useEffect } from 'react';
import parse from 'html-react-parser';

import './content.css';

import { IconButton } from '@mui/material';
import './content.css';

import { LanguageContext } from '../LanguageProvider';
// import LanguageSelector from '../LanguageSelector';


let timeoutId = null; // Declare this outside of the handleClickAnswer function

const guide_text = {
    EN: 'Welcome to the Greenvolve Game. Click on any of the 5 green city element categories to see the related questions. Think carefully your answer and choose the one which looks the most suitable. Were you correct or wrong? Try again with another answer for the same question and see what happens. You can click on the icons above each question or press the "Next" button to go to the next one. Click on "Back" button to go back to the main categories where you can choose another category to play. ', // English
    ES: 'Bienvenido al Juego Greenvolve. Haz clic en cualquiera de las 5 categorías de elementos verdes de la ciudad para ver las preguntas relacionadas. Piensa bien tu respuesta y elige la que te parezca más adecuada. ¿Acertaste o te equivocaste? Vuelve a intentarlo con otra respuesta para la misma pregunta y mira a ver qué pasa. Puedes hacer clic en los iconos que aparecen encima de cada pregunta o pulsar el botón "Siguiente" para pasar a la siguiente. Pulsa el botón "Atrás" para volver a las categorías principales, donde podrás elegir otra categoría para jugar.', // Spanish
    GR: 'Καλώς ήρθατε στο παιχνίδι Greenvolve. Κάντε κλικ σε οποιαδήποτε από τις 5 κατηγορίες στοιχείων της πράσινης πόλης για να δείτε τις σχετικές ερωτήσεις. Σκεφτείτε προσεκτικά την απάντησή σας και επιλέξτε αυτή που σας φαίνεται πιο κατάλληλη. Είχατε δίκιο ή άδικο; Δοκιμάστε ξανά με μια άλλη απάντηση για την ίδια ερώτηση και δείτε τι θα συμβεί. Μπορείτε να κάνετε κλικ στα εικονίδια πάνω από κάθε ερώτηση ή να πατήσετε το κουμπί "Επόμενο" για να μεταβείτε στην επόμενη. Κάντε κλικ στο κουμπί "Πίσω" για να επιστρέψετε στις κύριες κατηγορίες όπου μπορείτε να επιλέξετε μια άλλη κατηγορία για να παίξετε.', // Greek
    IT: 'Benvenuti al gioco Greenvolve. Cliccate su una delle 5 categorie di Green City Elements della città per vedere le relative domande. Pensate bene alla vostra risposta e scegliete quella che vi sembra più adatta. Hai risposto correttamente o male? Riprovate con un\'altra risposta alla stessa domanda e vedete cosa succede.È possibile fare clic sulle icone sopra ogni domanda o premere il pulsante "Avanti" per passare alla domanda successiva.Fare clic sul pulsante "Indietro" per tornare alle categorie principali, dove è possibile scegliere un\'altra categoria per giocare.', // Italian
    HU: 'Üdv a Greenvolve játékban! Kattints az 5 kategória egyikére a zöld város elemekhez kapcsolódó kérdések megtekintéséhez! Gondold át alaposan a kérdést, és válaszd ki a legmegfelelőbb választ! Igazad volt vagy tévedtél? Próbálkozz újra egy másik válasszal, és nézd meg, mi történik! A kérdések feletti ikonokra kattintva vagy a „Tovább” gomb megnyomásával léphetsz a következő kérdésre. Kattints a "Vissza" gombra, hogy visszatérj a fő kategóriákhoz, ahol kiválaszthatsz egy másik kategóriát a játékhoz.', // Hungarian
    LV: 'Laipni lūgti Greenvolve spēlē. Noklikšķiniet uz kādas no 5 zaļo pilsētas elementu kategorijām, un apskatiet ar to sistītos jautājumus. Rūpīgi pārdomājiet savu atbildi un izvēlieties to, kas šķiet vispiemērotākā. Vai atbildējāt pareizi vai nepareizi? Pamēģiniet vēlreiz ar citu variantu un paskatieties, kas notiks. Varat noklikšķināt uz ikonām virs katra jautājuma vai nospiest pogu "Nākamais", lai pārietu uz nākamo jautājumu. Nospiediet pogu "Atpakaļ", lai atgrieztos pie 5 galvenajām sadaļām.' // Latvian
};


function ContentGuide() {
    const { language } = useContext(LanguageContext);
    // For next button 

    const itemIndexMap = {
        'item1': 0,
        'item2': 1,
        'item3': 2,
        'item4': 3,
        'item5': 4,
        'item6': 5
    };


    useEffect(() => {
        // setActiveButton(currentIndex);
        console.log('la');
        // console.log('DEBUG ACTIVE BUTTON: ', activeButton);
        // setActiveButton(activeButton);

    }, [language]);



    return (
        <div className='return-div-energy-transition'>
            <div className='grid-xbuttons-questionshugger'>

                <div className='row-xcolumns-grid-xbuttons-images6'>

                </div>
                <div className='guide-content'>
                    <div className="new-content">
                        <div className="content-container">
                            <p className="text-guide" style={{ textAlign: 'justify' }}>{guide_text[language]}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContentGuide;
