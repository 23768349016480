import React, { useState, useEffect } from 'react';

import './App.css';  // Adjust the path depending on where your CSS file is located
// import i18n from 'i18next'; // assuming i18next is used for internationalization

import MyGridComponent from './components/MyGridComponent';
import { LanguageProvider } from './components/LanguageProvider';

// import { LanguageProvider } from './LanguageProvider';

// import BirdAnimation from './components/bird/BirdAnimation';
// import './components/bird.css';

function App() {

  const [showGrid, setShowGrid] = useState(false);
  const [language, setLanguage] = useState('EN');


  const welcomeTr = {
    EN: 'Welcome to the Greenvolve game', // English
    ES: 'Bienvenido al juego Greenvolve', // Spanish
    GR: 'Καλώς ήρθατε στο παιχνίδι του Greenvolve', // Greek
    IT: 'Benvenuti nel gioco di Greenvolve', // Italian
    HU: 'Üdvözöljük a Greenvolve játékban', // Hungarian
    LV: 'Laipni lūgti Greenvolve spēlē' // Latvian
  };

  const home_txt = {
    EN: 'Home', // English
    ES: 'Inicio', // Spanish
    GR: 'Αρχική', // Greek
    IT: 'Home', // Italian
    HU: 'Kezdőlap', // Hungarian
    LV: 'Sākums' // Latvian
  }

  const translations = {
    home: {
      EN: 'Home',
      ES: 'Inicio',
      GR: 'Αρχική',
      IT: 'Home',
      HU: 'Kezdőlap',
      LV: 'Sākums',
    },
  };

  function translate(key, language) {
    return translations[key][language] || key;
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const language = params.get('lang');
    if (language) {
      // Change the app's language based on the 'lang' parameter
      setLanguage(language);

    }
    const timer = setTimeout(() => {
      setShowGrid(true);
    }, 3000);  // Adjust the delay time as needed



    return () => clearTimeout(timer);
  }, [language]);




  return (
    <div className="App">
      <div className="background"></div>
      <div class="ball-sun">
        <a href="https://greenvolve-project.eu/" class="home-link">{translate('home', language)}</a>
      </div>

      {
        !showGrid &&
        <div className='first-message'>
          <h1>{welcomeTr[language]}</h1>
        </div>
      }
      {
        showGrid && <div className="grid-animation">
          <LanguageProvider><MyGridComponent
          // title1="Climate Change Mitigation"
          // title2="Circular Economy"
          // title3="Digitalization"
          // title4="Energy Transition"
          /></LanguageProvider> </div>
      }
    </div >
  );
}



export default App;
